<template>
   <div>
      <router-link to="/account/user/security/" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/security/' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Change Password</router-link>
      <router-link to="/account/user/security/2-fa-settings" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/security/2-fa-settings' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">2FA Settings</router-link>
      <router-link to="/account/user/security/anti-phishing" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/security/anti-phishing' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Anti-phishing</router-link>
      <router-link to="/account/user/security/security-questions" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/security/security-questions' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Security Questions</router-link>
      <router-link to="/account/user/security/finger-print-authorization" class="block py-1 px-5 my-4 hover:text-indigo-900 font-medium" :class="$route.path === '/account/user/security/finger-print-authorization' ? 'border-l-2 border-indigo-900 text-indigo-900 font-bold' : '' ">Finger Print Authorization</router-link>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
   name: "SideNav",

   data() {
      return {
         isLoading: false
      }
   },

   methods: {
      
   },

   computed: {
      ...mapGetters("auth", ["user"]),
   },
}
</script>